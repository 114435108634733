import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../components/home/home.vue';
import AllCategory from '../components/allmusic/allcategory.vue';
import AllMusicCategory from '../components/allmusic/allmusiccategory.vue';
import Mixes from '../components/allmusic/mixes.vue';
import CategoryMusic from '../components/allmusic/categorymusic.vue';
import Favourites from '../components/favourites/favourites.vue';
import Playlist from '../components/playlist/playlist.vue';
import PlaylistSongs from '../components/playlist/playlistsongs.vue';
import ContactUs from '../components/contactus/contactus.vue';
import LiveRadio from '../components/liveradio/liveradio.vue';
import LiveVideo from '../components/livevideo/livevideo.vue';
import Program from '../components/program/program.vue';
import Liveevent from '../components/liveevent/liveevent.vue';
import Search from '../components/search/search.vue';
import Splashvideo from '../components/splashvideo/splashvideo.vue';

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'splashvideo',
		component: Splashvideo
	},
	{
		path: '/home',
		name: 'Home',
		component: Home
	},
	{
		path: '/contactus',
		name: 'ContactUs',
		component: ContactUs
	},
	{
		path: '/livevideo',
		name: 'LiveVideo',
		component: LiveVideo
	},
	{
		path: '/liveradio',
		name: 'LiveRadio',
		component: LiveRadio
	},
	{
		path: '/program',
		name: 'Program',
		component: Program
	},
	{
		path: '/liveevent',
		name: 'Liveevent',
		component: Liveevent
	},
	{
		path: '/allcategory',
		name: 'AllCategory',
		component: AllCategory
	},
	{
		path: '/allmusiccategory/:menuId',
		name: 'AllMusicCategory',
		component: AllMusicCategory
	},
	{
		path: '/categorymusic/:categoryId',
		name: 'CategoryMusic',
		component: CategoryMusic
	},
	{
		path: '/playlist',
		name: 'Playlist',
		component: Playlist
	},
	{
		path: '/playlistsongs/:playlistID',
		name: 'PlaylistSongs',
		component: PlaylistSongs
	},
	{
		path: '/favourites',
		name: 'Favourites',
		component: Favourites
	},
	{
		path: '/search',
		name: 'Search',
		component: Search
	},
	{
		path: '/mixes',
		name: 'Mixes',
		component: Mixes
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to) {
		document.getElementById('app').scrollIntoView();
		if (to.hash) {
			return {
				selector: to.hash,
				behavior: 'smooth',
				return: {x: 0, y: 0}
			}
		}
	},
	scrollToTop() {
		window.scrollTo(0,0);
	}
});

export default router
